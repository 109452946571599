@import '../../../styles/partials/index';

.FormDisplay {
    width: 100vw;
    max-width: 500px;
    padding: 50px 24px;
    margin-left: auto;
    margin-right: auto;

    > * {
        margin-bottom: 50px;
    }
}

.FormDisplay__terms {
    @extend %regular14;
    color: $neutral-60;

    .TextButton {
        @extend %regular14;
    }
}

.FormDisplay__modal {
    width: 460px;
    max-width: calc(95vw - 40px);
    height: 525px;
    max-height: 90vh;
    color: $neutral-40;

    strong {
        color: $neutral-40;
    }
}

