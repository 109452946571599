$white: #fff;
$neutral-98: #f9f9fc;
$neutral-95: #eff0f6;
$neutral-90: #dfe2ee;
$neutral-85: #cfd4e5;
$neutral-80: #bfc6dc;
$neutral-70: #a1abc8;
$neutral-60: #8490b1;
$neutral-50: #6a7798;
$neutral-40: #515e7e;
$neutral-30: #394665;
$neutral-20: #21304d;

$blue-200: #8bd4f7;
$blue-300: #64c3f2;
$blue-400: #4eb6ef;
$blue-500: #3fa9ed;
$blue-600: #3b9bde;
$blue-700: #3588ca;

$red-100: #f6cacf;
$red-200: #e19597;
$red-300: #d26d6f;
$red-400: #d94e4e;
$red-500: #db3d36;
$red-600: #cd3534;
$red-700: #bb2c2f;

$orange-300: #f7b464;
$orange-600: #f18838;

$amber-100: #f8f2c1;
$amber-200: #f3ea99;
$amber-300: #eee271;
$amber-400: #ebdd55;
$amber-500: #e8d739;
$amber-600: #e6c634;
$amber-700: #e3af2d;
$amber-800: #e09825;

$green-100: #b8eedc;
$green-300: #3ed8af;
$green-400: #00ce9d;
$green-500: #00c48b;
$green-600: #00b47e;
$green-700: #00a16f;

$purple-300: #8b8ad0;

@mixin svgColor($color) {
    svg.iconColor use {
        fill: $color;
    }

    svg.iconColor path {
        fill: $color;
    }
}

%boxShadowLight {
    // as in Card figma design August 2020
    box-shadow: 0 0 4px rgba($neutral-20, 0.1);
}
%boxShadowMedium {
    // as in Card:hover figma design August 2020
    box-shadow: 0 0 6px rgba($neutral-20, 0.2);
}
%boxShadowDark {
    // unused as of August 2020
    box-shadow: 0 0 3px rgba($neutral-20, 0.5);
}
