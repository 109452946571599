@import '../../../../styles/partials/index';

.TimeInput__label {
    outline: none;
}

.TimeInput__menuContent {
    display: flex;
    align-items: center;
    margin: 10px;

    > * + * {
        margin-left: 10px;
    }
}