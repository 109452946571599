@import '../../../styles/partials/index';

.Nugget {
    @extend %semibold14;
    padding: 1px 10px;
    height: 30px;

    display: inline-flex;
    align-items: center;
    width: max-content;

    border-radius: 10px;
    border: 1px solid $neutral-90;
    box-shadow: 0 0 4px rgba($neutral-20, 0.1);

    > .Icon {
        @include svgColor($neutral-85);
        margin-right: 5px;
    }

    .IconButton {
        margin-left: 5px;
    }
}

.Nugget--canDrag {
    @extend %cursor-canDrag;
    background-color: $white;
    color: $neutral-20;
    transform: translate(0, 0); // added to prevent background showing on sreenshot preview
    
    &:hover {
        box-shadow: 0 0 6px rgba($neutral-20, 0.25);
    }


    &:active {
        box-shadow: none;
    }
}

.Nugget--cantDrag {
    background-color: $neutral-98;
    color: $neutral-30;
    cursor: default;
}

.Nugget--dragging {
    @extend %cursor-isDragging;
    opacity: 0.5;
}

.Nugget--squareRight {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
}

.Nugget--colored {
    color: $white;
    box-shadow: none;
}
