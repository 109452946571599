@import '../../../styles/partials/index';

.TextButton {
    @extend %regular12;
    @extend %shortTransition;
    border: none;
    cursor: pointer;
    width: fit-content;

    &:focus {
        outline: none;
        color: $blue-500;
    }

    &:hover {
        color: $blue-500;
    }

    &:active {
        color: $blue-600;
    }

    &.TextButton--disabled {
        color: $neutral-80;
        cursor: default;
    }
}

.TextButton--blue {
    color: $blue-500;
}

.TextButton--gray {
    color: $neutral-80;
}
