@import '../../../../styles/partials/index';

.EditableText {
    text-overflow: inherit;
}

.EditableText * {
    // this is a bit of a hack; this specificity is greater than .InputBorder but smaller than .InputBorder:hover
    background: transparent;
}
