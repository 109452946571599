@import '../../../styles/partials/index';
$tooltip-size: 12px;
$tooltip-inner-size: 11px;

.ToolTip {
    display: inline-block;
}

.ToolTip > .ToolTip__base {
    @extend %semibold14;
    @extend %shortTransition;
    @include svgColor($neutral-40);
    filter: drop-shadow(0 0 6px rgba($neutral-20, 0.25));
    max-width: 400px;

    &.place-bottom {
        &::before {
            border-left: $tooltip-size solid transparent;
            border-right: $tooltip-size solid transparent;
            top: -$tooltip-size;
            margin-left: -$tooltip-size;
        }

        &::after {
            border-left: $tooltip-inner-size solid transparent;
            border-right: $tooltip-inner-size solid transparent;
            top: -$tooltip-inner-size;
            margin-left: -$tooltip-inner-size;
        }
    }

    &.place-top {
        &::before {
            border-left: $tooltip-size solid transparent;
            border-right: $tooltip-size solid transparent;
            bottom: -$tooltip-size;
            margin-left: -$tooltip-size;
        }

        &::after {
            border-left: $tooltip-inner-size solid transparent;
            border-right: $tooltip-inner-size solid transparent;
            bottom: -$tooltip-inner-size;
            margin-left: -$tooltip-inner-size;
        }
    }

    &.place-left {
        &::before {
            border-top: $tooltip-size solid transparent;
            border-bottom: $tooltip-size solid transparent;
            right: -$tooltip-size;
            margin-top: -$tooltip-size;
        }

        &::after {
            border-top: $tooltip-inner-size solid transparent;
            border-bottom: $tooltip-inner-size solid transparent;
            right: -$tooltip-inner-size;
            margin-top: -$tooltip-inner-size;
        }
    }

    &.place-right {
        &::before {
            border-top: $tooltip-size solid transparent;
            border-bottom: $tooltip-size solid transparent;
            left: -$tooltip-size;
            margin-top: -$tooltip-size;
        }

        &::after {
            border-top: $tooltip-inner-size solid transparent;
            border-bottom: $tooltip-inner-size solid transparent;
            left: -$tooltip-inner-size;
            margin-top: -$tooltip-inner-size;
        }
    }
}

.ToolTip.ToolTip--disabled > .ToolTip__base {
    // adding a third level to guarantee that this overrides .__react_component_tooltip.show
    // (both have the same specificity, and the order in the cloud is different than the order in development)
    opacity: 0;
}

.ToolTip.ToolTip--hideArrow {
    .ToolTip__base {
        margin: 0;

        &::before {
            border: none;
        }

        &::after {
            border: none;
        }
    }
}

.ToolTip--normal > .ToolTip__base {
    background-color: $neutral-40;
    color: $white;
    border: 1px solid $neutral-30;

    &.place-bottom {
        &::before {
            border-bottom: $tooltip-size solid $neutral-30;
        }

        &::after {
            border-bottom: $tooltip-inner-size solid $neutral-40;
        }
    }

    &.place-top {
        &::before {
            border-top: $tooltip-size solid $neutral-30;
        }

        &::after {
            border-top: $tooltip-inner-size solid $neutral-40;
        }
    }

    &.place-left {
        &::before {
            border-left: $tooltip-size solid $neutral-30;
        }

        &::after {
            border-left: $tooltip-inner-size solid $neutral-40;
        }
    }

    &.place-right {
        &::before {
            border-right: $tooltip-size solid $neutral-30;
        }

        &::after {
            border-right: $tooltip-inner-size solid $neutral-40;
        }
    }
}

.ToolTip--amber > .ToolTip__base {
    background-color: $amber-100;
    color: $amber-800;
    border: 1px solid $amber-800;

    &.place-bottom {
        &::before {
            border-bottom: $tooltip-size solid $amber-800;
        }

        &::after {
            border-bottom: $tooltip-inner-size solid $amber-100;
        }
    }

    &.place-top {
        &::before {
            border-top: $tooltip-size solid $amber-800;
        }

        &::after {
            border-top: $tooltip-inner-size solid $amber-100;
        }
    }

    &.place-left {
        &::before {
            border-left: $tooltip-size solid $amber-800;
        }

        &::after {
            border-left: $tooltip-inner-size solid $amber-100;
        }
    }

    &.place-right {
        &::before {
            border-right: $tooltip-size solid $amber-800;
        }

        &::after {
            border-right: $tooltip-inner-size solid $amber-100;
        }
    }
}

.ToolTip--red > .ToolTip__base {
    background-color: $red-100;
    color: $red-700;
    border: 1px solid $red-700;

    &.place-bottom {
        &::before {
            border-bottom: $tooltip-size solid $red-700;
        }

        &::after {
            border-bottom: $tooltip-inner-size solid $red-100;
        }
    }

    &.place-top {
        &::before {
            border-top: $tooltip-size solid $red-700;
        }

        &::after {
            border-top: $tooltip-inner-size solid $red-100;
        }
    }

    &.place-left {
        &::before {
            border-left: $tooltip-size solid $red-700;
        }

        &::after {
            border-left: $tooltip-inner-size solid $red-100;
        }
    }

    &.place-right {
        &::before {
            border-right: $tooltip-size solid $red-700;
        }

        &::after {
            border-right: $tooltip-inner-size solid $red-100;
        }
    }
}

.ToolTip__children {
    display: flex;
    align-items: center;
    justify-content: center;
}
