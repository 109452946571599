@import '../../../styles/partials/index';

.Timeline * {
    box-sizing: content-box; // low priority TODO: change to border-box;
}

.Timeline__itemContainer {
    position: relative;
}

.Timeline__itemContainer--left {
    text-align: right;
}

.Timeline__itemContainer--centerleft {
    text-align: right;
    margin-right: calc(50% - 26px);
}

.Timeline__itemContainer--centerright {
    margin-left: calc(50% - 26px);
}

.Timeline__itemContainer:last-child .TimelineItem {
    min-height: unset;
}
