.TextInput {
    text-overflow: inherit;
    position: relative;

    > .Icon {
        // Icon is absolutely positioned so the input can fill the full InputBorder
        // -- this makes login credential autofill look better (it changes background of input component)
        position: absolute;
        right: 10px;
        pointer-events: none;
    }
}

.TextInput--icon .InputBase {
    padding-right: 36px;
}
