@import '../../../styles/partials/index';

.ScrollArea {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-height: inherit;
    max-width: inherit;

    .ps {
        max-height: inherit;
        max-width: inherit;
    }

    .ps__rail-y,
    .ps__rail-x {
        background-color: transparent;
        z-index: 1;

        &:hover,
        &.ps--clicking {
            background-color: transparent;
        }
    }

    .ps__thumb-y,
    .ps__thumb-x {
        background-color: $neutral-70 !important;

        &:hover,
        &:active,
        &.ps--clicking {
            opacity: 1;
        }
    }

    .ps__rail-y {
        margin: 2px 0;
    }

    .ps__rail-x {
        margin: 0 2px;
    }

    .ps__thumb-y,
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y.ps--clicking > .ps__thumb-y,
    .ps__thumb-y.ps--clicking {
        width: 8px;
        border-radius: 8px;
    }

    .ps__thumb-x,
    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x.ps--clicking > .ps__thumb-x,
    .ps__thumb-x.ps--clicking {
        height: 8px;
        border-radius: 8px;
    }
}

.ScrollArea__topGradient,
.ScrollArea__bottomGradient {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    pointer-events: none;
    transition: all 0.25s ease;
}

.ScrollArea__leftGradient,
.ScrollArea__rightGradient {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    pointer-events: none;
    transition: all 0.25s ease;
}

.ScrollArea__topGradient {
    top: 0;
    background: linear-gradient(to bottom, rgba($white, 1), rgba($white, 0));
}

.ScrollArea__bottomGradient {
    bottom: 0;
    background: linear-gradient(to top, rgba($white, 1) 0%, rgba($white, 0) 100%);
}

.ScrollArea__leftGradient {
    left: 0;
    background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 100%);
}

.ScrollArea__rightGradient {
    right: 0;
    background: linear-gradient(to left, rgba($white, 1) 0%, rgba($white, 0) 100%);
}

.ScrollArea--dark {
    .ps__thumb-y,
    .ps__thumb-x {
        background-color: $neutral-90 !important;
    }

    .ScrollArea__topGradient {
        background: linear-gradient(to bottom, rgba($neutral-50, 1), rgba($neutral-50, 0));
    }

    .ScrollArea__bottomGradient {
        background: linear-gradient(to top, rgba($neutral-50, 1) 0%, rgba($neutral-50, 0) 100%);
    }

    .ScrollArea__leftGradient {
        background: linear-gradient(to right, rgba($neutral-50, 1) 0%, rgba($neutral-50, 0) 100%);
    }

    .ScrollArea__rightGradient {
        background: linear-gradient(to left, rgba($neutral-50, 1) 0%, rgba($neutral-50, 0) 100%);
    }
}
