.SearchInput {
    text-overflow: inherit;
    position: relative;
    border-radius: 10px;

    .Icon {
        position: absolute;
        right: 15px;
    }

    .InputBase {
        padding-left: 20px;
        padding-right: 45px;
    }

    .InputBase--small {
        padding-left: 15px;
        padding-right: 35px;
    }

    // get rid of the default html input search styles
    input[type='text']::-ms-clear,
    input[type='text']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        display: none;
    }
}
