@import '../../../styles/partials/index';

.KPI__value {
    @extend %bold14;
}

.KPI--horizontal {
    .KPI__label {
        display: inline-block;
        width: 96px;
    }

    .KPI__value {
        display: inline-block;
    }
}

.KPI--big {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;

    .KPI__label {
        @extend %semibold14;
        color: $neutral-40;
    }

    .KPI__value {
        @extend %bold24;
        color: $neutral-40;
    }

    .KPI__row {
        display: flex;
        flex-direction: row;
    }

    .KPI__column {
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .KPI__column--right {
        margin-left: 5px;
        margin-bottom: 5px;
    }

    .KPI__unit {
        @extend %bold14;
        color: $neutral-40;
        margin-top: 3px;
        margin-bottom: 2px;
    }
}
