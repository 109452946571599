@import '../../../styles/partials/index';

.SidePanel {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

.SidePanel__main {
    background-color: $white;
    display: flex;
    height: 100%;
    position: relative;
    flex: 0 0 0;
    max-width: 50%;

    > .SidePanelButton {
        position: absolute;
        top: 61px;
        left: -13px;
        z-index: 2;
    }
}

.SidePanel__sidebar {
    display: flex;
    flex-direction: column;
    width: 50px;
    padding-top: 18px;

    > * + * {
        margin-top: 10px;
    }
}

.SidePanel__children {
    flex: 1;
    overflow: hidden;
}

.SidePanel--open .SidePanel__main {
    @extend %boxShadowMedium;
    flex: none;
    align-items: right;
    margin: auto;
}

.SidePanel__tab {
    flex-grow: 1;
    max-width: calc(100% - 50px); // 50px for sidebar
}
