@import '../../../../styles/partials/index';

.FileUpload {
    height: 100%;

    > input {
        opacity: 0;
        position: absolute;
        // opacity is used to hide the file input instead of visibility: hidden or display: none
        // because assistive technology interprets the latter two styles to mean the file input isn't interactive.
    }

    .Section {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .IconButton {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.Input--gray {
    .FileUpload .Section {
        background: $neutral-98;
    }
}

.FileUpload__container {
    height: 100%;
    width: 100%;
    min-height: 275px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.FileUpload__message {
    @extend %bold18;
    margin-bottom: 10px;
}

.FileUpload__messageSeperator {
    margin-right: 5px;
}

.FileUpload__messageChooseFile {
    display: flex;
    align-items: center;

    .TextButton {
        @extend %regular14;
    }
}

.FileUpload__messageError {
    @extend %bold18;
    margin-bottom: 20px;
}

.FileUpload__restrictions {
    color: $neutral-60;
    position: absolute;
    bottom: 20px;
}

.FileUpload__ImageDiv {
    height: 150px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

#FileUpload__ImagePreview {
    max-height: 150px;
    max-width: 200px;
}

.FileUpload__fileName {
    @extend %bold18;
    word-break: break-all;
}
