#root {
    height: 100%;
}

#modal-root,
#fullscreen-modal-root {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    max-height: 100vh;
    overflow: auto;
}

#modal-root {
    z-index: 10000;
}

#fullscreen-modal-root {
    z-index: 9999;
}

#context-menu-root {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10001;
}

#notification-root {
    position: absolute;
    top: 30px;
    z-index: 100000;
    width: 100%;
    pointer-events: none;
}
