@import '../../../styles/partials/index';

.Modal {
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal__content {
    max-height: 95vh; // TODO: handle large modals on small screen sizes
    max-width: 95%;
}

.Modal__overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: $neutral-80;
    opacity: 0.6;
}
