.ThreeColumnHeader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    :nth-child(2) {
        margin: auto;
    }

    :nth-child(3) {
        margin-left: auto;
    }

    @include phone {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 15px;

        :nth-child(2),
        :nth-child(3) {
            margin: 0;
        }
    }
}
