@import '../../../../styles/partials/index';

.Job {
    padding: 10px 0;

    &.Job:hover {
        background-color: $neutral-95;
    }

    &:focus {
        outline: none;
    }

    > header {
        cursor: pointer;
        padding: 0 15px;
        height: 40px;
        display: flex;
        align-items: center;
    }
}

.Job__icon {
    margin-right: 15px;
    height: 20px;
    width: 20px;
}

.Job__name {
    @extend %semibold14;
    flex: 1 1 0;
    max-height: 50px;
    overflow: scroll;
}

.Job__status {
    @extend %regular12;
    color: $neutral-70;
    margin-left: 3px;
}

.Job__KPIs {
    display: block;
    padding: 0 15px;

    .KPI {
        margin-top: 5px;

        .KPI__label {    // TODO: I'd rather not do this.
            width: 78px;
        }
    }
}

.Job--failed .Icon {
    @include svgColor($red-400);
    color: $red-400;
}

.Job--continue .Icon {
    @include svgColor($amber-600);
    color: $amber-600;
}

.Job--completed .Icon {
    @include svgColor($green-500);
    color: $green-500;
}
