@import '../../../../styles/partials/index';

.ScaleInput {
    .RadioOption__label {
        display: none;
    }
}

.ScaleInput__labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.ScaleInput__scale {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ScaleInput__label {
    @extend %regular14;
    color: $neutral-20;

    &:last-child {
        text-align: right;
        margin-left: 10px;
    }
}

.ScaleInput--dark .ScaleInput__label {
    color: $white;
}
