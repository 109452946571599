$desktop: '(min-width: 1024px)';
$tablet: '(min-width: 768px) and (max-width: 1023px)';
$phone: '(min-width: 0px) and (max-width: 767px)';

// $desktop: "(min-width: 1024px)";
// $tablet: "(min-width: 768px)";
// $phone: "(min-width: 0px)";

@mixin phone {
    @media screen and #{$phone} {
        @content;
    }
}
@mixin tablet {
    @media screen and #{$tablet} {
        @content;
    }
}
@mixin desktop {
    @media screen and #{$desktop} {
        @content;
    }
}
