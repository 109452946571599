@import '../../../styles/partials/index';

.ListItem {
    display: flex;
    align-items: center;

    padding: 5px 15px;
    height: 40px;

    cursor: pointer;
    outline: none;

    .ListItem__name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.ListItem__type {
    line-height: 0; // vertically centers any <Icon> that is used as a listItem.type
    margin-right: 8px;
}

.ListItem__name {
    flex: 1;
}

.ListItem--sm {
    height: 30px;
}

.ListItem--default,
.ListItem--gray {
    .ListItem__type {
        @include svgColor($neutral-85);
        color: $neutral-85;
    }

    &:hover,
    &:focus {
        background-color: $neutral-95;
    }

    &:active,
    &.ListItem--active {
        @include svgColor($white);
        color: $white;
        background-color: $blue-500;

        .ListItem__type {
            @include svgColor($neutral-95);
            color: $neutral-95;
        }
    }
}

.ListItem--gray {
    background: $neutral-98;
}

.ListItem--dark {
    @include svgColor($white);
    background-color: $neutral-50;
    color: $white;

    &:hover,
    &:focus {
        background-color: $neutral-40;
    }

    &:active,
    &.ListItem--active {
        background-color: $neutral-40;
    }
}
