@import '../../../../styles/partials/index';

.MultiSelectInput {
    position: relative;

    .ContextMenu__hook {
        height: 40px;
    }
}

.MultiSelectInput__summary {
    display: flex;
    align-items: center;

    height: 40px;
    padding: 4px 36px 4px 12px;

    background: transparent;
    white-space: nowrap;
    outline: none;
    cursor: pointer;

    .Icon {
        position: absolute;
        right: 10px;
    }
}

.MultiSelectInput__summary--placeholder {
    color: $neutral-60;
}

.MultiselectInput__nuggets {
    display: flex;
    overflow: auto;
}

.MultiselectInput__nugget {
    background-color: $neutral-90;
    width: fit-content;
    border-radius: 4px;
    position: relative;
    padding: 3px 5px;
    overflow: hidden; // for the gradient effect off the 'x' icon
    flex-shrink: 0;
    cursor: default;

    div {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .Icon {
        @include svgColor($neutral-60);
        width: 30px;
        opacity: 0;
        position: absolute;
        right: 0;
        top: calc(50% - 8px); // 8px = half of the Icon's height.  This is a way to center it verically
        background: $neutral-90;
        box-shadow: 0 0 15px 15px $neutral-90;
    }

    &:hover {
        .Icon {
            opacity: 1;
        }
    }
}

.MultiSelectInput--vertical {
    .MultiSelectInput__display {
        flex-direction: column;
        align-items: stretch;
    }

    .MultiSelectInput__summary {
        flex: 0 0 auto;
    }

    .MultiselectInput__nuggets {
        flex-flow: row wrap;
        max-height: 180px;
        padding: 0 4px;
    }

    .MultiselectInput__nugget {
        margin: 0 4px 8px;
    }

    &.MultiSelectInput--small {
        .MultiselectInput__nuggets {
            padding: 0 2px;
        }

        .MultiselectInput__nugget {
            margin: 0 2px 4px;
        }
    }
}

.MultiSelectInput--horizontal {
    .MultiSelectInput__display {
        flex-direction: row-reverse;
    }

    .MultiSelectInput__summary {
        flex: 1 0 auto;
        justify-content: flex-end;
    }

    .MultiSelectInput__summary--placeholder {
        justify-content: flex-start;
    }

    .MultiselectInput__nuggets {
        margin-left: 4px;
        margin-right: -4px;
    }

    .MultiselectInput__nugget {
        margin: 4px;
        max-width: calc(100% - 8px); // subtracting off margin
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.MultiSelectInput--small {
        .MultiselectInput__nuggets {
            margin: 0;
        }

        .MultiselectInput__nugget {
            margin: 4px 0 4px 4px;
            max-width: calc(100% - 4px); // subtracting off margin
        }
    }
}

.MultiSelectInput--small {
    .MultiSelectInput__summary {
        height: 25px;
    }

    .ContextMenu__hook {
        height: 25px;
    }

    .MultiselectInput__nugget {
        padding: 1px 5px;
    }
}

.MultiSelectInput__contextMenu {
    .SearchInput .Icon {
        right: 10px; // had some trouble implementing 'small' search input styles in `search/styles.scss`
    }

    .ListInput {
        padding: 0;
        max-height: 200px;
        overflow: auto;
    }

    .ListItem:hover,
    .ListItem:focus,
    .ListItem:active {
        background: $neutral-40;
        
        .Checkbox {
            border-width: 2px;
        }
    }
}

.MultiSelectInput__header {
    padding: 10px 10px 5px;
}
