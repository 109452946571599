/* stylelint-disable */
@import '../../../../styles/partials/index';

.Codebox {
    /* CSS below has been taken from codemirror with minor style edits. original here: https://codemirror.net/lib/codemirror.css */
    /* BASICS */

    /* Set height, width, borders, and global font properties here */
    .CodeMirror {
        cursor: text;
        height: 300px;
        color: $neutral-20;
        direction: ltr;
        overflow: auto;
        border-radius: 5px;
        // border: solid thin $neutral-90;
        font-family: Courier;
        line-height: 20px;
        height: inherit;
    }

    /* PADDING */
    .CodeMirror-lines {
        padding: 22px 10px 0 20px; /* Vertical padding around content */
    }

    .CodeMirror pre {
        padding: 0 30px; /* Horizontal padding of content */
    }

    .CodeMirror-scrollbar-filler,
    .CodeMirror-gutter-filler {
        background-color: $white; /* The little square between H and V scrollbars */
    }

    /* GUTTER */
    .CodeMirror-gutters {
        background-color: $white;
        white-space: nowrap;
        width: 20px;
    }

    .CodeMirror-linenumber {
        padding: 0 3px 0 5px;
        min-width: 30px;
        text-align: right;
        color: $neutral-70;
        white-space: nowrap;
    }

    .CodeMirror-guttermarker {
        color: $neutral-20;
    }

    /* CURSOR */

    .CodeMirror-cursor {
        border-left: 1px solid $neutral-20;
        border-right: none;
        width: 0;
    }
    /* Shown when moving in bi-directional text */
    .CodeMirror div.CodeMirror-secondarycursor {
        border-left: 1px solid silver;
    }

    .cm-fat-cursor .CodeMirror-cursor {
        width: auto;
        border: 0 !important;
        background: #7e7;
    }

    .cm-fat-cursor div.CodeMirror-cursors {
        z-index: 1;
    }

    .cm-fat-cursor-mark {
        background-color: rgba(20, 255, 20, 0.5);
        animation: blink 1.06s steps(1) infinite;
    }

    .cm-animate-fat-cursor {
        width: auto;
        border: 0;
        animation: blink 1.06s steps(1) infinite;
        background-color: #7e7;
    }
    @keyframes blink {
        0% {
        }

        50% {
            background-color: transparent;
        }

        100% {
        }
    }

    /* Can style cursor different in overwrite (non-insert) mode */
    // .CodeMirror-overwrite .CodeMirror-cursor {
    // }

    .cm-tab {
        display: inline-block;
        text-decoration: inherit;
    }

    .CodeMirror-rulers {
        position: absolute;
        left: 0;
        right: 0;
        top: -50px;
        bottom: -20px;
        overflow: hidden;
    }

    .CodeMirror-ruler {
        border-left: 1px solid #ccc;
        top: 0;
        bottom: 0;
        position: absolute;
    }

    /* DEFAULT THEME */

    .cm-header,
    .cm-strong {
        font-weight: bold;
    }

    .cm-em {
        font-style: italic;
    }

    .cm-link {
        text-decoration: underline;
    }

    .cm-strikethrough {
        text-decoration: line-through;
    }

    .cm-s-default .cm-operator,
    .cm-s-default .cm-keyword {
        color: #5f54ba;
    }

    .cm-s-default .cm-number {
        color: #cd3534;
    }

    .cm-s-default .cm-attribute,
    .cm-s-default .cm-def {
        color: $green-700;
    }

    .cm-s-default .cm-tag,
    .cm-s-default .cm-link,
    .cm-s-default .cm-meta,
    .cm-s-default .cm-bracket,
    .cm-s-default .cm-variable,
    .cm-s-default .cm-variable-2,
    .cm-s-default .cm-variable-3 {
        color: $blue-700;
    }

    // For punctuation
    .cm-s-default .cm-punctuation,
    * {
        color: $neutral-40;
    }

    .cm-s-default .cm-comment {
        color: $neutral-60;
    }

    .cm-s-default .cm-string {
        color: $orange-600;
    }

    .cm-s-default .cm-header {
        color: #f28ad5;
    }

    .cm-s-default .cm-property,
    .cm-s-default .cm-builtin,
    .cm-s-default .cm-error,
    .cm-s-default .cm-qualifier {
        color: #5f54ba;
    }

    .cm-s-default .cm-atom {
        color: #e3af2d;
    }

    // Haven't used:
    // .cm-s-default .cm-quote {
    //     color: #f28ad5;
    // }

    // .cm-negative {
    //     color: #f28ad5;
    // }

    // .cm-positive {
    //     color: #f28ad5;
    // }

    // .cm-s-default .cm-string-2 {
    //     color: #f28ad5;
    // }

    // .cm-s-default .cm-hr {
    //     color: #f28ad5;
    // }

    // .cm-invalidchar {
    //     color: #f28ad5;
    // }

    .CodeMirror-composing {
        border-bottom: 2px solid;
    }

    /* Default styles for common addons */

    // div.CodeMirror span.CodeMirror-matchingbracket {
    //     color: #0b0;
    // }

    // div.CodeMirror span.CodeMirror-nonmatchingbracket {
    //     color: #a22;
    // }

    .CodeMirror-matchingtag {
        background: rgba(255, 150, 0, 0.3);
    }

    .CodeMirror-activeline-background {
        background-color: rgba(74, 144, 226, 0.07);
    }

    /* STOP */

    /* The rest of this file contains styles related to the mechanics of
     the editor. You probably shouldn't touch them. */

    .CodeMirror {
        position: relative;
        overflow: hidden;
        background: $white;
    }

    .CodeMirror-scroll {
        overflow: auto !important; /* Things will break if this is overridden */
        /* 30px is the magic margin used to hide the element's real scrollbars */
        /* See overflow: hidden in .CodeMirror */
        margin-bottom: -30px;
        margin-right: -30px;
        padding-bottom: 30px;

        height: 100%;
        outline: none; /* Prevent dragging from highlighting the element */
        position: relative;
        will-change: transform;
    }

    .CodeMirror-sizer {
        margin-top: 20px;
        position: relative;
        margin-top: 30px;
        border-right: 0px solid transparent;
        * {
            overflow: visible;
        }
    }

    /* The fake, visible scrollbars. Used to force redraw during scrolling
     before actual scrolling happens, thus preventing shaking and
     flickering artifacts. */
    .CodeMirror-vscrollbar,
    .CodeMirror-hscrollbar,
    .CodeMirror-scrollbar-filler,
    .CodeMirror-gutter-filler {
        position: absolute;
        z-index: 6;
        display: none;
    }

    .CodeMirror-vscrollbar {
        right: 0;
        top: 0;
        // overflow-x: hidden;
        overflow-y: auto;
    }

    .CodeMirror-hscrollbar {
        bottom: 0;
        left: 0;
        overflow-y: hidden;
        overflow-x: auto;
    }

    .CodeMirror-scrollbar-filler {
        right: 0;
        bottom: 0;
    }

    .CodeMirror-gutter-filler {
        left: 0;
        bottom: 0;
    }

    .CodeMirror-gutters {
        position: absolute;
        left: 0;
        top: 0;
        min-height: 100%;
        z-index: 3;
    }

    .CodeMirror-gutter {
        white-space: normal;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: -30px;
    }

    .CodeMirror-gutter-wrapper {
        position: absolute;
        z-index: 4;
        background: none !important;
        border: none !important;
    }

    .CodeMirror-gutter-background {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 4;
    }

    .CodeMirror-gutter-elt {
        position: absolute;
        cursor: default;
        z-index: 4;
    }

    .CodeMirror-gutter-wrapper ::selection {
        background-color: transparent;
    }

    .CodeMirror-lines {
        cursor: text;
        min-height: 1px; /* prevents collapsing before first draw */
    }

    .CodeMirror pre {
        /* Reset some styles that the rest of the page might have set */
        border-radius: 0;
        border-width: 0;
        background: transparent;
        font-family: inherit;
        font-size: inherit;
        margin: 0;
        white-space: pre;
        word-wrap: normal;
        color: inherit;
        z-index: 2;
        position: relative;
        overflow: visible;
        -webkit-tap-highlight-color: transparent;
        font-variant-ligatures: contextual;
    }

    .CodeMirror-wrap pre {
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: normal;
    }

    .CodeMirror-linebackground {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .CodeMirror-linewidget {
        position: relative;
        z-index: 2;
        padding: 0.1px; /* Force widget margins to stay inside of the container */
    }

    // .CodeMirror-widget {
    // }

    .CodeMirror-rtl pre {
        direction: rtl;
    }

    .CodeMirror-code {
        outline: none;
    }

    /* Force content-box sizing for the elements where we expect it */
    .CodeMirror-scroll,
    .CodeMirror-sizer,
    .CodeMirror-gutter,
    .CodeMirror-gutters,
    .CodeMirror-linenumber {
        box-sizing: content-box;
    }

    .CodeMirror-measure {
        position: absolute;
        width: 100%;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }

    .CodeMirror-cursor {
        position: absolute;
        pointer-events: none;
    }

    .CodeMirror-measure pre {
        position: static;
    }

    div.CodeMirror-cursors {
        z-index: 3;
    }

    div.CodeMirror-dragcursors {
        visibility: visible;
    }

    .CodeMirror-focused div.CodeMirror-cursors {
        visibility: visible;
    }

    .CodeMirror-selected {
        background-color: rgba(74, 144, 226, 0.17);
    }

    .CodeMirror-focused .CodeMirror-selected {
        background-color: rgba(74, 144, 226, 0.17);
    }

    .CodeMirror-crosshair {
        cursor: crosshair;
    }

    .CodeMirror-line::selection,
    .CodeMirror-line > span::selection,
    .CodeMirror-line > span > span::selection {
        background: #d7d4f0;
    }

    .cm-searching {
        background-color: #ffa;
        background-color: rgba(255, 255, 0, 0.4);
    }

    /* Used to force a border model for a node */
    .cm-force-border {
        padding-right: 0.1px;
    }

    @media print {
        /* Hide the cursor when printing */
        .CodeMirror div.CodeMirror-cursors {
            visibility: hidden;
        }
    }

    /* See issue #2901 */
    .cm-tab-wrap-hack::after {
        content: '';
    }

    /* Help users use markselection to safely style text background */
    span.CodeMirror-selectedtext {
        background: none;
    }
}
.Codebox-readOnly {
    div.CodeMirror-cursors {
        visibility: hidden;
        position: relative;
        z-index: 3;
    }
}
