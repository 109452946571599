@import './_responsive', './colors';

$body-family: 'Nunito', 'Lato', sans-serif;
$header-family: 'Rubik', 'Lato' , sans-serif;

%bold36 {
    font-family: $header-family;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    color: $neutral-30;
}

%bold28 {
    font-family: $header-family;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    color: $neutral-30;
}

%bold24 {
    font-family: $header-family;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    color: $neutral-30;
}

%bold20 {
    font-family: $header-family;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    color: $neutral-30;
    @include phone {
        font-size: 22px; // font sizes are a bit larger on phone
    }
}

%bold18 {
    font-family: $header-family;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    color: $neutral-30;
    @include phone {
        font-size: 20px; // font sizes are a bit larger on phone
    }
}

%bold14 {
    font-family: $body-family;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    
    @include phone {
        font-size: 16px; // font sizes are a bit larger on phone
    }
}

// there is no bold12 because it is not readable on low-res monitors

%semibold14 {
    font-family: $body-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    @include phone {
        font-size: 16px; // font sizes are a bit larger on phone
    }
}

%semibold12 {
    font-family: $body-family;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    @include phone {
        font-size: 14px; // font sizes are a bit larger on phone
    }
}

%regular20 {
    font-family: $body-family;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    @include phone {
        font-size: 22px; // font sizes are a bit larger on phone
    }
}

%regular14 {
    font-family: $body-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: normal;
    text-transform: none;
    @include phone {
        font-size: 16px; // font sizes are a bit larger on phone
    }
}

%regular12 {
    font-family: $body-family;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.02em;
    text-transform: none;
    @include phone {
        font-size: 14px; // minimum font size on phone is 14px
    }
}
