@import '../../../styles/partials/index';

@mixin iconSize($value) {
    width: $value;
    height: $value;
    min-width: $value;
    min-height: $value;
    font-size: $value;
    line-height: $value;
}

.Icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    i,
    img,
    .isvg,
    svg {
        height: inherit;
        width: inherit;
        pointer-events: none;
    }

    i,
    img,
    .isvg,
    svg,
    path,
    use {
        transition: inherit;
    }
}

.Icon--literal {
    text-align: center;
    font-weight: bold;
}

.Icon--pulsing {
    animation: wiggle 1.5s ease 0s infinite;
    transform-origin: center center;

    svg path {
        animation: colorPulse 1.5s ease 0s infinite;
    }

    svg rect {
        // should maybe be changing color like the path.
        stroke: $blue-500;
    }
}

.Icon--literal {
    @extend %bold14;
    width: fit-content;
}

@keyframes wiggle {
    0% {
        transform: scale(1);
    }

    40% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.98);
    }

    60% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes colorPulse {
    0% {
        fill: $blue-400;
    }

    50% {
        fill: $blue-500;
    }

    100% {
        fill: $blue-400;
    }
}

.Icon--selected {
    @include svgColor($blue-600);
    color: $blue-600;
}
