@import '../../../../styles/partials/index';

.InputBorder {
    @extend %shortTransition;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: inherit;
    display: flex;
    align-items: center;

    .Icon {
        @extend %shortTransition;
    }

    &:focus-within {
        transition: none;

        .Icon {
            transition: none;
        }
    }
}

.InputBorder--rounded {
    border-radius: 10px;
}

.InputBorder--autosize {
    width: min-content;
}

.InputBorder--light,
.InputBorder--gray {
    box-shadow: inset 0 0 0 1px $neutral-90;
    background-color: $white;
    color: $neutral-30;

    &:hover {
        box-shadow: inset 0 0 0 3px $neutral-90;
    }

    &:focus-within {
        box-shadow: inset 0 0 0 1px $blue-500;
        background-color: $white; // adding background-color on focux-within for editable inputs
        color: $neutral-20;

        .Icon {
            @include svgColor($blue-500);
            color: $blue-500;
        }
    }

    .Icon {
        @include svgColor($neutral-80);
        color: $neutral-80;
    }

    .InputBase::placeholder {
        color: $neutral-60;
    }
}

.InputBorder--gray {
    // the gray styles are almost exactly the same as the above so we are skipping a bit
    background-color: $neutral-98;

    &:focus-within {
        background-color: $neutral-98; // adding background-color on focux-within for editable inputs
    }
}

.InputBorder--dark {
    background-color: $neutral-50;
    color: $white;
    box-shadow: inset 0 0 0 1px $neutral-80;

    &:hover {
        box-shadow: inset 0 0 0 3px $neutral-80;
    }

    &:focus-within {
        box-shadow: inset 0 0 0 1px $neutral-50;
        background-color: $neutral-98; // adding background-color on focux-within for editable inputs
        color: $neutral-30;
        font-weight: normal;

        .Icon {
            @include svgColor($blue-500);
            color: $blue-500;
        }

        .InputBase::placeholder {
            color: $neutral-60;
        }
    }

    .Icon {
        @include svgColor($neutral-80);
        color: $neutral-80;
    }

    .InputBase::placeholder {
        color: $neutral-80;
    }
}

.InputBorder.InputBorder--error {
    // two levels of specificity to overwrite hover styles
    box-shadow: inset 0 0 0 1px $red-700;

    .Icon {
        @include svgColor($red-700);
        color: $red-700;
    }
}

.InputBorder--dark.InputBorder--error {
    background: $white;
    color: $neutral-40;

    &:focus-within {
        color: $neutral-30;
    }

    .InputBase::placeholder {
        color: $neutral-60;
    }
}

.InputBorder.InputBorder--disabled {
    // two levels of specificity to overwrite all the styles above
    box-shadow: none;
    background-color: $neutral-95;

    &.InputBorder--dark {
        background-color: $neutral-70;
    }
}
