@import '../../../../styles/partials/index';

.KPIStatus {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.KPIStatus__label {
    @extend %regular14;
    margin-left: 2px;
    color: $neutral-70;
}

.KPIStatus--green .Icon {
    @include svgColor($green-500);
}

.KPIStatus--red .Icon {
    @include svgColor($red-400);
}

.KPIStatus--blue .Icon {
    @include svgColor($blue-500);
}
