@import 'daisyui/dist/full.css';
@import './_animations';
@import './_colors';
@import './_components';
@import './_cursors';
@import './_fonts';
@import './_layouts';
@import './_responsive';
@import './_scrollbar';
@import './_shapes';
