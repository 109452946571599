@import '../../../../styles/partials/index';

.SidePanelButton {
    @extend %shortTransition;
    @include svgColor($neutral-70);


    display: flex;
    align-items: center;
    justify-content: center;

    height: 25px;
    width: 25px;

    border-radius: 10px;
    background: $neutral-95;
    box-shadow: inset 0 0 0 1px $neutral-90, 0 0 2px rgba($neutral-70, 0.4);

    cursor: pointer;
    outline: none;

    &:hover,
    &:active {
        box-shadow: inset 0 0 0 3px $neutral-90,;
    }

    &:active {
        transition: none;
        box-shadow: inset 0 0 10px rgba($neutral-70, 0.4), inset 0 0 0 3px $neutral-90, 0 0 2px rgba($neutral-70, 0.4);
    }
}
