@import '../../../../styles/partials/index';

.ContextMenu__subMenu {
    border: 1px solid $neutral-70;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba($neutral-20, 0.25);
    margin-top: -1px;

    &:first-child {
        margin-top: 0;
    }

    > * {
        z-index: 1;
        position: relative;
        border-radius: 5px;
        padding: 0 10px 10px 5px;
    }

    > .ContextMenu__hook {
        padding: 0;
    }

}

.ContextMenu__subMenuHeading {
    @extend %regular12;
    padding: 5px 9px;
}
