@import '../../../styles/partials/index';

.Badge {
    @extend %bold14;
    @include svgColor($white);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
    min-width: 50px;
    width: fit-content;
    height: 20px;
    white-space: nowrap;
    cursor: default;
    border-radius: $border-radius-medium;
    background: $neutral-80;
    border: 1px solid $neutral-70;
    color: $white;
}

.Badge--success {
    @include svgColor($green-500);
    background: $green-500;
    border: 1px solid $green-600;
}

.Badge--error {
    @include svgColor($red-400);
    background: $red-400;
    border: 1px solid $red-700;
}

.Badge--warning {
    @include svgColor($amber-600);
    background: $amber-600;
    border: 1px solid $amber-800;
}

.Badge--info {
    @include svgColor($neutral-70);
    background: $neutral-95;
    color: $neutral-70;
    border: 1px solid $neutral-70;
}

.Badge--secondary {
    @include svgColor($neutral-80);
    background: $neutral-98;
    color: $neutral-80;
    border: 1px solid $neutral-80;
}

.Badge--blue {
    @include svgColor($blue-500);
    background: $blue-400;
    border: 1px solid $blue-500;
}

.Badge--pulsing {
    animation: badgePulse 2.5s ease 0s infinite;
}
@keyframes badgePulse {
    0% {
        color: $neutral-70;
        border: 1px solid $neutral-70;
    }

    50% {
        color: $blue-500;
        border: 1px solid $blue-500;
    }

    100% {
        color: $neutral-70;
        border: 1px solid $neutral-70;
    }
}
