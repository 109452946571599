@import '../../../../styles/partials/index';

.TimelineItem {
    display: inline-block;
    min-height: 70px;
}

.TimelineItem--left {
    text-align: right;
}

.Timeline__line {
    border: solid $neutral-80 thin;
    position: absolute;
    top: 25px;
    bottom: -5px;
}

.Timeline__line--left {
    right: 25px; /*16 margin + 2 circle border width + 12/2 circle width - 2/2 line width */
}

.Timeline__line--right {
    left: 25px;
}

.Timeline__line--center {
    left: calc(50% - 1px);
}

.TimelineItem__details {
    display: inline-block;
    max-width: calc(100% - 52px);
}

.TimeLineItem__mainDetails {
    padding: 5px;
    border-radius: $border-radius;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $neutral-98;
    }
}

.TimelineItem__details_title {
    @extend %bold14;
    margin-bottom: 4px;
}

.TimelineItem__details_date {
    @extend %regular12;
    color: $neutral-80;
}

.TimelineItem__dropdown {
    margin: 10px 0;
}

.TimelineItem__dropdown--collapsed {
    display: none;
}

.TimelineItem__bullet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;

    border: solid 2px;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin: 5px 16px 0;

    .Icon {
        @include svgColor($neutral-20);
        display: none;
    }
}

.TimelineItem__bullet--expandable {
    cursor: pointer;
}

.TimelineItem__bullet-expandable:hover .Icon {
    display: block;
}

.TimelineItem__bullet--success {
    color: $green-500;
}

.TimelineItem__bullet--error {
    color: $red-400;
}

.TimelineItem__bullet--info {
    color: $blue-600;
}
