@import '../../../../styles/partials/index';

.ContextMenuItem {
    @extend %semibold14;
    @extend %shortTransition;

    display: flex;
    align-items: center;

    padding: 9px;
    margin: -1px;
    position: relative;

    color: $white;
    text-align: left;

    outline: none;
    cursor: pointer;
    border-radius: 5px;

    &:hover,
    &:focus,
    &:active,
    &--active {
        background-color: $neutral-40;
        box-shadow: inset 0 0 0 3px $neutral-80;
        z-index: 2;
    }

    &:active {
        box-shadow: inset 0 0 12px rgba($white, 0.4), inset 0 0 0 3px $neutral-80;
        transition: none;
    }

    &.ContextMenuItem--active {
        background-color: $neutral-98;
        color: $blue-500;
        box-shadow: inset 0 0 0 1px $neutral-50;
    }
}

.ContextMenuItem__name {
    overflow: hidden;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ContextMenuItem__type {
    display: flex;

    > .Icon {
        @include svgColor($neutral-80);
        color: $neutral-80;
        margin-right: 10px;
    }
}

.ContextMenuItem__rightChildren {
    margin-left: auto;
}
