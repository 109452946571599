@import '../../../styles/partials/index';

.Chip {
    @extend %regular12;
    @extend %shortTransition;
    @include svgColor($neutral-70);
    color: $neutral-60;
    padding: 5px 7px;
    box-shadow: inset 0 0 0 1px $neutral-90;
    background-color: $neutral-98;
    border-radius: 3px;
    width: fit-content;
    height: 22px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    outline: none;

    &:hover {
        box-shadow: inset 0 0 0 2px $neutral-90;
        background-color: $neutral-95;
    }

    &:active,
    &.Chip--active {
        background-color: $neutral-95;
        box-shadow: inset 0 0 12px rgba($neutral-20, 0.4), inset 0 0 0 2px $neutral-90;
    }
}

.Chip--highlighted {
    @include svgColor($neutral-40);
    color: $neutral-40;
    box-shadow: inset 0 0 0 1px $blue-200;

    &:hover {
        box-shadow: inset 0 0 0 2px $blue-200;
    }

    &:active,
    &.Chip--active {
        box-shadow: inset 0 0 12px rgba($neutral-20, 0.4), inset 0 0 0 2px $blue-200;
    }
}

.Chip--red {
    box-shadow: inset 0 0 0 1px $red-400;

    &:hover {
        box-shadow: inset 0 0 0 2px $red-400;
    }

    &:active,
    &.Chip--active {
        box-shadow: inset 0 0 12px rgba($neutral-20, 0.4), inset 0 0 0 2px $red-400;
    }
}

.Chip__iconText {
    margin-left: 5px;
}
