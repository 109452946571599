@import '../../../styles/partials/index';

.Button {
    @extend %bold14;
    @extend %shortTransition;

    display: flex;
    align-items: center;
    justify-content: center;

    width: max-content;

    cursor: pointer;
    user-select: none;
    outline: none;

    box-shadow: inset 0 0 0 1px var(--box-shadow-color);

    &:hover,
    &:focus {
        box-shadow: inset 0 0 0 3px var(--box-shadow-color);
    }

    &:active,
    &.Button--loading {
        transition: none;
        box-shadow: inset 0 0 12px var(--box-shadow-active), inset 0 0 0 3px var(--box-shadow-color);
    }
}

.Button--disabled {
    cursor: default;
}

.Button--small {
    height: 25px;
    padding: 4px 9px;
    border-radius: 8px;
}

.Button--large {
    height: 35px;
    padding: 7px 16px;
    border-radius: 10px;
}

.Button--blue {
    --box-shadow-color: #{$blue-600};
    --box-shadow-active: #{rgba($neutral-20, 0.4)};

    @include svgColor($white);
    background-color: $blue-400;
    color: $white;

    &.Button--disabled {
        --box-shadow-color: #{$blue-200};
        background-color: $blue-200;
    }
}

.Button--gray {
    --box-shadow-color: #{$neutral-80};
    --box-shadow-active: #{rgba($neutral-20, 0.4)};

    @include svgColor($neutral-30);
    color: $neutral-30;
    background-color: $neutral-95;

    &.Button--disabled {
        --box-shadow-color: #{$neutral-98};
        @include svgColor($neutral-80);
        color: $neutral-80;
        background-color: $neutral-98;
    }
}

.Button--dark {
    --box-shadow-color: #{$neutral-80};
    --box-shadow-active: #{rgba($white, 0.4)};

    @include svgColor($white);
    color: $white;
    background-color: $neutral-50;

    &.Button--disabled {
        --box-shadow-color: #{$neutral-70};
        background-color: $neutral-70;
    }
}


.Button--orange {
    --box-shadow-color: #{$orange-300};
    --box-shadow-active: #{rgba($neutral-20, 0.4)};

    @include svgColor($white);
    color: $white;
    background-color: $orange-600;

    &.Button--disabled {
        --box-shadow-color: #{$neutral-98};
        @include svgColor($neutral-80);
        color: $neutral-80;
        background-color: $neutral-98;
    }
}

.Button--green {
    --box-shadow-color: #{$green-500};
    --box-shadow-active: #{rgba($neutral-20, 0.4)};

    @include svgColor($white);
    color: $neutral-98;
    background-color: $green-300;

    &.Button--disabled {
        --box-shadow-color: #{$neutral-98};
        @include svgColor($neutral-80);
        color: $neutral-80;
        background-color: $neutral-98;
    }
}

.Button--red {
    --box-shadow-color: #{$red-700};
    --box-shadow-active: #{rgba($neutral-20, 0.4)};

    @include svgColor($white);
    color: $white;
    background-color: $red-400;

    &.Button--disabled {
        --box-shadow-color: #{$red-100};
        background-color: $red-100;
    }
}
