@import '../../../styles/partials/index';

.ModalOverlay {
    position: absolute;
    left: 0;
    top: 60px;
    right: 0;
    bottom: 0;
    background-color: transparentize($color: $white, $amount: 0.2);
    z-index: 2;
}

.ModalOverlay__content {
    width: 100%;
    background: $white;
    box-shadow: 0 4px 6px rgba($neutral-20, 0.2);
    border-bottom: 1px solid $neutral-90;
    border-radius: 0 0 10px 10px;
    position: relative;
}

.ModalOverlay__body {
    padding: 0 20px;
}

.ModalOverlay__footer {
    padding: 1px 15px 15px;
    border-top: 1px solid $neutral-95;

    display: flex;
    justify-content: flex-end;

    .Button {
        margin-left: 10px;
        margin-top: 15px;
    }
}
