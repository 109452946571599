@import '../../../../styles/partials/index';

.DateInput {
    .Icon {
        cursor: pointer;
    }
}

.DateInput__menu {
    width: 250px;

    header {
        padding: 5px;
    }
}

.DateInput__CalendarDisplay {
    background-color: $neutral-60;
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 25px;

}

.DateInput__calendarDisplay--day {
    grid-template-columns: repeat(7, 1fr);
}

.DateInput__Title {
    @extend %bold14;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
}

.DateInput__Headers {
    display: flex;
    justify-content: space-around;
    height: 25px;
    line-height: 25px;
}

.DateInput__label {
    outline: none;
}
