@import '../../../../../styles/partials/index';

.MonthCalendar {
    background-color: $neutral-60;
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 25px;
    border-radius: 5px;
}

.MonthCalendar__day {
    height: 20px;
    width: 20px;
    margin: 2.5px auto;
    border-radius: 4px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $neutral-50;
    }
}

.MonthCalendar__day--invalid {
    color: $neutral-70;
    cursor: default;

    &:hover {
        background-color: inherit;
    }
}

.MonthCalendar__day--current {
    background-color: $blue-500;

    &:hover {
        background-color: $blue-500;
    }
}
