@import '../../../../styles/partials/index';

.SelectButtons {
    display: flex;
    box-shadow: inset 0 0 0 1px $neutral-90;
    border-radius: 10px;
    background-color: $white;
    height: 35px;
}

.SelectButtons__button {
    @extend %bold14;
    @extend %shortTransition;
    flex: 1;
    height: 35px;
    padding: 0 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;

    &:active {
        transition: none;
    }
}

.SelectButtons--light {
    .SelectButtons__button {
        color: $neutral-20;
        background-color: transparent;

        &:hover {
            background-color: $neutral-98;
            box-shadow: inset 0 0 0 3px $neutral-80;
        }

        &:active {
            background-color: $neutral-98;
            box-shadow: inset 0 0 12px rgba($neutral-20, 0.4), inset 0 0 0 3px $neutral-80;
        }
    }

    .SelectButtons__button.SelectButtons__button--selected {
        box-shadow: inset 0 0 0 1px $blue-600;
        background-color: $blue-400;
        color: $white;
    }
}

.SelectButtons--gray {
    .SelectButtons__button {
        color: $neutral-20;
        background-color: transparent;

        &:hover {
            background-color: $neutral-95;
            box-shadow: inset 0 0 0 3px $neutral-80;
        }

        &:active {
            background-color: $neutral-95;
            box-shadow: inset 0 0 12px rgba($neutral-20, 0.4), inset 0 0 0 3px $neutral-80;
        }
    }

    .SelectButtons__button.SelectButtons__button--selected {
        box-shadow: inset 0 0 0 1px $blue-600;
        background-color: $blue-400;
        color: $white;
    }
}

.SelectButtons--dark {
    box-shadow: inset 0 0 0 1px $neutral-80;
    background-color: $neutral-50;

    .SelectButtons__button {
        background-color: transparent;
        color: $white;

        &:hover {
            background-color: $neutral-60;
            box-shadow: inset 0 0 0 3px $neutral-80;
        }

        &:active {
            background-color: $neutral-60;
            box-shadow: inset 0 0 12px rgba($white, 0.4), inset 0 0 0 3px $neutral-80;
        }
    }

    .SelectButtons__button.SelectButtons__button--selected {
        box-shadow: inset 0 0 0 1px $neutral-50;
        background-color: $neutral-98;
        color: $blue-500;
    }
}

.SelectButtons--error {
    box-shadow: inset 0 0 0 1px $red-700;
}

.SelectButtons--disabled {
    cursor: auto;
    box-shadow: none;

    .SelectButtons__button {
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }

        &:active {
            box-shadow: none;
        }
    }
}

.SelectButtons--gray {
    background-color: $neutral-98;
}
