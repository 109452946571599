@import '../../../../styles/partials/index';

.TextArea {
    height: 100%;
    min-height: 100px;

    textarea {
        width: 100%;
        height: 100%;
        min-height: 98px;
        resize: none;
        color: inherit;
        background-color: transparent;
        font: inherit;
        overflow: auto;
        border: 0;
        outline: none;
        padding: 7px 10px 2px; // if you change this padding, you need to change the calculation in the editableTextArea component
        margin-bottom: 5px;

        &::placeholder {
            @extend %regular14;
            color: $neutral-80;
        }
    }
}
