@import '../../../../styles/partials/index';

.ButtonList {
    width: max-content;
    border-radius: $border-radius;
    padding: 5px 5px 5px 3px;
    display: flex;

    .IconButton {
        margin-left: 2px;
    }
}

.ButtonList--gray {
    background-color: $neutral-98;
}
