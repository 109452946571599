@import './partials/index';

html,
body {
    @extend %regular12;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: $neutral-98;
    color: $neutral-40;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

a {
    @extend %semibold12;
    color: $blue-500;
    text-decoration: none;

    &:hover {
        color: $blue-700;
    }
}

h1 {
    @extend %bold36;
}

h2 {
    @extend %bold24;
}

h3 {
    @extend %bold20;
}

h4 {
    @extend %bold18;
}

h5 {
    @extend %bold14;
}

div {
    display: block;
}

ul {
    margin: unset;
}

button {
    border: 0;
    padding: 0;
}

hr {
    margin: 15px 0;
    height: 1px;
    color: $neutral-90;
    background-color: $neutral-90;
    border: 0;
}

strong {
    color: $neutral-20;
}
