@import '../../../../styles/partials/index';

.NumberInput {
    .InputBase {
        text-align: center;
    }

    .IconButton {
        background-color: transparent;
        border: none !important;
        padding: 0 5px;
        margin: 0 5px;

        &::before {
            display: none;
        }
    }
}
