@import '../../../styles/partials/index';

.Notification {
    @extend %semibold14;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: $border-radius-large;
    pointer-events: auto; // here because notification-root has pointer-events: none to allow clicking in the margins of the Notification
}

.Notification__message {
    word-break: break-all;
    overflow: auto;
    flex: 1;
    margin: 0 5px;
}

.Notification--toast {
    width: fit-content;
    min-width: 300px;
    max-width: calc(100vw - 40px);
    white-space: nowrap;
    margin: 5px auto;
    z-index: 9999;
}

.Notification--info {
    @include svgColor($neutral-40);
    background-color: $neutral-80;
    color: $neutral-40;
    border: 1px solid $neutral-40;

    .IconButton:hover {
        @include svgColor($neutral-80);
    }
}

.Notification--success {
    @include svgColor($green-700);
    background-color: $green-100;
    color: $green-700;
    border: 1px solid $green-700;
}

.Notification--warn {
    @include svgColor($amber-800);
    background-color: $amber-100;
    color: $amber-800;
    border: 1px solid $amber-800;
}

.Notification--error {
    @include svgColor($red-700);
    background-color: $red-100;
    color: $red-700;
    border: 1px solid $red-700;
}
