@import '../../../styles/partials/index';

.ContextMenu__hook {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}

.ContextMenu__hook--open {
    pointer-events: all;
    cursor: pointer;
}

.ContextMenu {
    position: absolute;

    min-width: 132px;
    max-width: calc(100vw - 21px);
    max-height: calc(100vh - 21px);

    background-color: $neutral-50;
    border: 1px solid $neutral-40;
    border-radius: 5px;
    color: $white;
    box-shadow: 0 0 6px rgba($neutral-20, 0.25);

    overflow: visible; // ensure the "tip" appears
    z-index: 1; // so the context menu forms a new stacking context
}

.ContextMenu__ListHeading {
    @extend %regular12;
    padding: 5px 9px;
}

.ContextMenu__tip {
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: $neutral-50;
    border: 1px solid $neutral-40;
    border-bottom: none;
    border-left: none;
    z-index: -1; // behind the other content in the context menu
}
