@import '../../../styles/partials/index';

.PoweredByWhiteWhale {
    @extend %regular14;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 45px;
        margin-top: 6px;
    }
}

.PoweredByWhiteWhale--disabled {
    a {
        pointer-events: none;
        cursor: default;
    }
}
