@import '../../../styles/partials/index';

.JobQueue {
    margin: 0 15px 0 30px;

    @include tablet{
        margin-right: 30px;
    }

    @include phone{
        margin-right: 30px;
    }
}

.JobQueue__title {
    @extend %bold14;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 40px;
    color: $neutral-60;

    border-bottom: 1px solid $neutral-80;
    border-top: 1px solid $neutral-80;

    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $neutral-95;
    }
}

.JobQueue__Jobs {
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow-y: auto;
    word-break: break-word;
}
