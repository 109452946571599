@import '../../../../styles/partials/index';

.EditableTextArea {
    text-overflow: inherit;
}

.EditableTextArea * {
    // this is a bit of a hack; this specificity is greater than .InputBorder but smaller than .InputBorder:hover
    box-shadow: none;
    background: transparent;
}

.EditableTextArea .TextArea {
    min-height: 22px;
    height: 100%;

    textarea {
        min-height: 20px;
        line-height: 14px;
    }
}
