@import '../../../../styles/partials/index';

.Switch {
    @extend %shortTransition;
    height: 25px;
    width: 60px;
    box-shadow: inset 0 0 0 1px $neutral-80;
    border-radius: 15px;
    position: relative;
    background-color: $white;
    display: inline-block;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
        box-shadow: inset 0 0 0 2px $neutral-80;

        .Switch__button {
            box-shadow: inset 0 0 0 2px $neutral-50;
        }
    }
}

.Switch__button {
    @extend %shortTransition;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 19px;
    height: 19px;
    box-shadow: inset 0 0 0 1px $neutral-50;
    background-color: $neutral-60;
    border-radius: 100%;
}

.Switch--small {
    height: 20px;
    width: 40px;
    border-radius: 10px;

    .Switch__button {
        background-color: $neutral-60;
        box-shadow: inset 0 0 0 1px $neutral-50;
        top: 3px;
        left: 3px;
        width: 14px;
        height: 14px;
    }

    &.Switch--selected .Switch__button {
        left: calc(100% - 17px);
    }
}

.Switch.Switch--gray {
    background-color: $neutral-98;
}

.Switch.Switch--dark {
    background-color: $neutral-50;
    box-shadow: inset 0 0 0 1px $neutral-80;

    &:hover {
        box-shadow: inset 0 0 0 2px $neutral-80;

        .Switch__button {
            box-shadow: inset 0 0 0 2px $neutral-80;
        }
    }

    .Switch__button {
        box-shadow: inset 0 0 0 1px $neutral-80;
        background-color: $neutral-98;
    }
}

.Switch--selected {
    background-color: $white;

    .Switch__button {
        left: calc(100% - 22px);
        background-color: $blue-400;
        box-shadow: inset 0 0 0 1px $blue-600;
    }

    &:hover {
        box-shadow: inset 0 0 0 2px $neutral-80;

        .Switch__button {
            box-shadow: inset 0 0 0 2px $blue-600;
        }
    }
}

.Switch--dark.Switch--selected {
    background-color: $neutral-98;
    box-shadow: inset 0 0 0 1px $neutral-50;

    .Switch__button {
        background-color: $blue-400;
        box-shadow: inset 0 0 0 1px $blue-600;
    }

    &:hover {
        box-shadow: inset 0 0 0 2px $neutral-50;

        .Switch__button {
            box-shadow: inset 0 0 0 2px $blue-600;
        }
    }
}

.Switch.Switch--error,
.Switch--small.Switch--error {
    box-shadow: inset 0 0 0 1px $red-700;

    &:hover {
        box-shadow: inset 0 0 0 1px $red-700;
    }

    .Switch__button {
        box-shadow: inset 0 0 0 1px $red-700;

        &:hover {
            box-shadow: inset 0 0 0 1px $red-700;
        }
    }
}

.Switch--small.Switch--error.Switch--dark {
    box-shadow: inset 0 0 0 1px $red-700;

    .Switch__button {
        box-shadow: inset 0 0 0 1px $red-700;
    }
}

.Switch.Switch--disabled {
    cursor: auto;
    box-shadow: none;

    .Switch__button {
        cursor: auto;
        box-shadow: none;
    }
}

.Switch.Switch--disabled.Switch--dark {
    &:hover {
        box-shadow: none;
    }

    .Switch__button {
        box-shadow: none;
    }
}
