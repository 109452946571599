/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
/* TODO: these styles need to be rewritten */
@import '../../../styles/partials/index';

.DataTable {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;

    .ScrollArea__topGradient,
    .ScrollArea__rightGradient,
    .ScrollArea__leftGradient {
        top: 40px;
        bottom: 0px;
    }
}

.DataTable__table {
    border: 1px solid $neutral-90;
    border-radius: 5px;
    color: $neutral-20;
    position: relative;
    overflow: auto;
    background-color: $white;
    flex: 0 1 auto;
    max-height: min-content;

    table {
        text-align: left;
        width: 100%;
        border-spacing: 0;

        * {
            border-spacing: 0;
        }

        thead {
            max-height: 40px;
        }

        th {
            position: sticky;
            top: 0;

            height: 40px;
            max-width: min-content;
            padding: 3px 10px;

            border: 1px solid $neutral-90;
            border-top-width: 0;
            border-left-width: 0;

            background-color: $neutral-98;
            color: $neutral-20;
            box-shadow: 4px 2px 8px $neutral-95;

            word-break: break-word;
            overflow: hidden;

            &:first-child {
                border-top-left-radius: 3px;
            }

            &:last-child {
                border-top-right-radius: 3px;
                border-right-width: 0;
            }
        }

        tr {
            max-height: 40px;

            &:hover {
                background-color: $neutral-98;
            }

            &:last-child {
                td {
                    border-bottom-width: 0;
                }
            }
        }

        td {
            max-width: 200px;
            height: 40px;
            padding: 0 10px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            border: 1px solid $neutral-90;
            border-left-width: 0;
            border-top-width: 0;

            &:last-child {
                border-right-width: 0;
            }
        }

        .Loader {
            height: 17px;
            padding: 10px;
            display: block;

            svg {
                border-radius: 4px;
            }
        }

        .DataTable__row--expanded {
            td {
                padding: 10px;
                white-space: pre-wrap;
                word-wrap: break-word;
                transition-duration: 0.2s;
            }
        }
    }
}

.DataTable--pageLoading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .Loader {
        transform: scale(1.3);
    }
}

.DataTable--pagination {
    .DataTable__table {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        tr {
            &:last-child {
                td {
                    border-bottom-width: 0;

                    &:first-child {
                        border-bottom-left-radius: 0;
                    }

                    &:last-child {
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }

    .DataTable--pageLoading {
        bottom: 39px;
    }
}

.DataTable--noData {
    tr:hover {
        background-color: inherit;
    }

    td {
        color: $neutral-70;
        text-align: center;
    }
}

.DataTable__footer {
    display: block;
    position: relative;
    background-color: $white;
    border: 1px solid $neutral-90;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .IconButton {
        position: absolute !important; // important because it needs to overwrite .Button--gray:active.Button--small:not(.Button--disabled)
        line-height: unset;
        top: 0px;

        &:first-child {
            left: 5px;
        }

        &:last-child {
            right: 5px;
        }
    }

    .DataTable__paginationInput {
        text-align: center;
        margin: 3px;

        span {
            margin-left: 5px;
            margin-right: 5px;
        }

        .Form {
            display: inline;

            .Input {
                @extend %regular12;
                display: inline-block;
            }
        }
    }
}

.DataTable--white {
    .DataTable__table th {
        background-color: $white;
    }

    .DataTable__footer {
        background-color: $white;
    }
}

.DataTable--noVerticals {
    .DataTable__table {
        border: 0;
        border-radius: 0;

        tr > :first-child {
            padding-left: 20px;
        }

        th {
            border-right-width: 0;
        }

        td {
            border-right-width: 0;
        }
    }
}
