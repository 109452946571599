@import '../../../../styles/partials/index';

.SliderText {
    display: flex;
    align-items: center;
    height: 40px;
}

.SliderText__labelButton {
    @extend %semibold14;
    margin-right: 15px;
    cursor: pointer;
    color: $neutral-20;

    &:focus {
        outline: none;
    }
}
