@import '../../../styles/partials/index';

.ColorPicker {
    width: 225px;
}

.ColorPicker__label {
    @extend %regular12;
    padding: 5px 5px 5px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CustomPicker {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
}

.CustomPicker__Alpha,
.CustomPicker__Hue {
    position: relative;
    border: 1px solid $neutral-80;
    border-radius: 3px;
    height: 10px;
    width: 100%;
    margin: 0 5px 5px;
}

.CustomPicker__Input {
    width: 100%;
    padding: 0;
    margin: 0 5px 5px;

    input {
        width: 100%;
    }

    .Icon {
        @include svgColor($neutral-98);

    }
}

.CustomPicker__Saturation {
    height: 100px;
    width: 100%;
    position: relative;
    border: 1px solid $neutral-80;
    border-radius: 5px;
    margin: 5px;

    div {
        width: 100%;
        border-radius: 4px;
    }
}

.CustomPicker__Slider {
    cursor: pointer;
    transform: translate(-50%, -18%);
    width: 14px;
    height: 14px;
    background-color: $neutral-98;
    border-radius: 24px;

    &:hover {
        box-shadow: 0 0 6px rgba($neutral-20, 0.5);
    }
}
