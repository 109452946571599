@import '../../../styles/partials/index';

.NotificationPopUp {
    background-color: $neutral-40;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: $border-radius-large;
    pointer-events: auto; // here because NotificationPopUp-root has pointer-events: none to allow clicking in the margins of the NotificationPopUp
    height: 40px;
    width: fit-content;
    max-width: calc(100vw - 40px);
    white-space: nowrap;
    margin: 5px auto;
    z-index: 9999;
}
.NotificationPopUp__description,
.NotificationPopUp__closeButton {
    @include svgColor($white);
}

.NotificationPopUp__text {
    display: flex;
    word-break: break-all;
    overflow: auto;
    margin: 0 10px;
    flex: 1;
    .NotificationPopUp__title {
        @extend %bold14;
        color: $white;
        margin-right: 5px;
    }
    .NotificationPopUp__subTitle {
        @extend %regular14;
        color: $neutral-95;
    }
}

.NotificationPopUp__primaryButton,
.NotificationPopUp__secondaryButton {
    margin-right: 10px;
}

.NotificationPopUp--info {
    .NotificationPopUp__icon {
        @include svgColor($blue-300);
    }
}

.NotificationPopUp--success {
    .NotificationPopUp__icon {
        @include svgColor($green-300);
    }
}

.NotificationPopUp--warning {
    .NotificationPopUp__icon {
        @include svgColor($amber-600);
    }
}

.NotificationPopUp--error {
    .NotificationPopUp__icon {
        @include svgColor($red-200);
    }
}
