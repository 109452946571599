@import '../../../styles/partials/index';

.Breadcrumb {
    @extend %bold18;
}

.Breadcrumb_separator {
    margin: 0 3px;
}

.Breadcrumb__link {
    @extend %bold18;
    color: $blue-500;

    &:hover {
        color: $blue-600;
    }
}