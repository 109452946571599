@import '../../../styles/partials/index';

.IconButton {
    @extend %shortTransition;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    width: max-content;

    background-color: transparent;
    box-shadow: inset 0 0 0 0 var(--box-shadow-color);
    padding: 3px; // for "literal" icon button

    cursor: pointer;
    outline: none;
    user-select: none; // sometimes double clicking on an iconbutton selects text

    &:hover,
    &:focus {
        box-shadow: inset 0 0 0 var(--box-shadow-width) var(--box-shadow-color);
    }

    &:active,
    &.IconButton--active {
        box-shadow: inset 0 0 10px var(--box-shadow-active), inset 0 0 0 var(--box-shadow-width) var(--box-shadow-color);
    }

    .Icon {
        @extend %shortTransition;
    }

    &:active {
        transition: none;

        .Icon {
            transition: none;
        }
    }
}

.IconButton__label {
    @extend %semibold14;
    margin: 0 5px;
}

.IconButton--light {
    --box-shadow-color: #{$neutral-90};
    --box-shadow-active: #{rgba($neutral-70, 0.4)};

    @include svgColor($neutral-70);
    color: $neutral-70;

    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        background-color: $neutral-95;
    }

    &.IconButton--selected {
        @include svgColor($blue-600);
        color: $blue-600;
    }

    &.IconButton--disabled {
        @include svgColor($neutral-90);
        color: $neutral-90;
    }
}

.IconButton--dark {
    --box-shadow-color: #{$neutral-80};
    --box-shadow-active: #{rgba($white, 0.4)};

    @include svgColor($white);
    color: $white;

    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        background-color: $neutral-50;
    }

    &.IconButton--selected {
        @include svgColor($blue-600);
        color: $blue-600;
    }

    &.IconButton--disabled {
        @include svgColor($neutral-80);
        color: $neutral-80;
    }
}

.IconButton--red {
    --box-shadow-color: #{$red-700};
    --box-shadow-active: #{rgba($red-700, 0.8)};

    @include svgColor($red-700);
    color: $red-700;

    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        @include svgColor($white);
        color: $white;
        background-color: $red-400;
    }

    &.IconButton--disabled {
        @include svgColor($red-100);
        color: $red-100;
        background-color: $red-400;
    }
}

.IconButton--yellow {
    --box-shadow-color: #{$amber-800};
    --box-shadow-active: #{rgba($amber-800, 0.8)};

    @include svgColor($amber-800);
    color: $amber-800;

    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        @include svgColor($white);
        background-color: $amber-600;
        color: $white;
    }

    &.IconButton--disabled {
        @include svgColor($amber-100);
        color: $amber-100;
        background-color: $amber-600;
    }
}

.IconButton--green {
    --box-shadow-color: #{$green-700};
    --box-shadow-active: #{rgba($green-700, 0.8)};

    @include svgColor($green-700);
    color: $green-700;

    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        @include svgColor($white);
        background-color: $green-500;
        color: $white;
    }

    &.IconButton--disabled {
        @include svgColor($green-100);
        color: $green-100;
        background-color: $green-500;
    }
}

.IconButton--redAlert {
    --box-shadow-color: #{$red-700};

    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        @include svgColor($white);
        color: $white;
        background-color: $red-400;
    }
}

.IconButton--mini {
    --box-shadow-width: 2px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

.IconButton--medium {
    --box-shadow-width: 3px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.IconButton--large {
    --box-shadow-width: 3px;
    width: 45px;
    height: 45px;
    border-radius: 10px;

    .IconButton__label {
        margin-left: 15px;
    }
}

.IconButton--hasLabel {
    width: auto;
}

.IconButton--disabled {
    cursor: default;

    &:hover,
    &:focus,
    &:active,
    &.IconButton--active {
        border-color: transparent;
        box-shadow: none;
        background-color: transparent;
    }
}
