@import '../../../../styles/partials/index';

.ListInput {
    height: 100%;
    display: flex;
    flex-direction: column;

    .Loader {
        height: 40px;
        padding: 10px;
        display: block;

        svg {
            border-radius: 4px;
        }
    }
}

.ListInput__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .Checkbox {
        margin-right: 10px;
    }

    > :last-child {
        flex: 1;
    }
}

.ListInput__empty {
    height: 40px;
    text-align: center;
    line-height: 40px;
}

.ListInput__List {
    flex: 1 1 auto;
    border-radius: 4px;
}

.ListInput--dark {
    .ListInput__List {
        border: 1px solid $neutral-80;
    }

    .ListInput__empty {
        color: $neutral-95;
    }
}

.ListInput--light {
    .ListInput__List {
        background: $white;
        border: 1px solid $neutral-80;
    }

    .ListInput__empty {
        color: $neutral-80;
    }

    .ListItem {
        border-bottom: 1px solid $neutral-90;
    }
}

.ListInput--gray {
    .ListInput__List {
        border: 1px solid $neutral-80;
        background: $neutral-98;
        color: $neutral-20;
    }

    .ListInput__empty {
        color: $neutral-80;
    }
}

.ListInput--noBorder {
    .ListInput__List {
        border: 0;
    }
}
