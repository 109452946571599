@import '../../../../styles/partials/index';

.JsonInput {
    .TextInput {
        cursor: pointer;
    }
}

.JsonInput__menu {
    width: 350px;
    max-height: 400px;
    padding: 0 10px 10px;

    header {
        padding: 5px;
    }
}

.JsonInput__menu--error {
    .JsonInput__Title {
        color: $red-400;
    }
}

.JsonInput__Title {
    @extend %bold14;
    display: flex;
    align-items: center;
    height: 30px;  
}

.JsonInput__label {
    outline: none;
}
