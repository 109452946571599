@import '../../../styles/partials/index';

.Page {
    // overflow-y: auto;
    // overflow-x: hidden;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.Page__mainWrapper {
    display: flex;
    flex-flow: column;
    flex: 1 1 0;
    height: 100%;
    overflow: auto;
}

.Page__sidebarExpand {
    display: flex;
    align-items: center;
    flex: 0 0 40px;
    margin-left: 20px;
    margin-top: 10px;
}

.Page__main {
    flex: 1;
    position: relative;
    margin: 10px 10px 0;
}

.Page__main--topbar {
    margin-top: 0;
}

.Page__main--listPane {
    margin-left: 0;
    @include tablet {
        margin-top: 0;
        margin-left: 10px;
        max-width: 100%;
    }
    @include phone {
        margin-top: 0;
        margin-left: 10px;
        max-width: 100%;
    }
}

.Page__sidebar_root {
    // the sidebar library uses inline styles
    flex: 1 1 0;
}

.Page__sidebar_listPane {
    // the sidebar library uses inline styles
    width: 300px;
}

.Page__topbarContainer {
    flex: 0 0 auto;
}

.Page__bottomPaneContainer {
    flex: 0 0 auto;
    position: absolute;
    bottom: 0;
    z-index: 40;
}
