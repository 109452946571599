@import '../../../../styles/partials/index';

.Checkbox {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 18px;
    height: 18px;
    min-width: 18px; // to prevent checkboxes from shrinking inside a flex container

    border-radius: 5px;
    border-width: 1px;
    border-style: solid;

    outline: none;
    cursor: pointer;

    transition: border-width 0.15s ease-in;

    &:hover {
        border-width: 2px;
    }

    &.Checkbox--disabled {
        border-width: 1px;
        cursor: unset;
    }

    .Icon {
        // icon is 16x16; this will center the icon within the Checkbox
        margin: 1px;
    }
}

.Checkbox--light {
    background-color: $white;
}

.Checkbox--gray {
    background-color: $neutral-98;
}

.Checkbox--dark {
    background-color: $neutral-50;
}

.Checkbox--unselected {
    // This border color is for both light and dark unselected checkboxes
    border-color: $neutral-80;
}

.Checkbox--selected {
    background-color: $blue-400;
    border-color: $blue-600;

    .Icon {
        @include svgColor($white);
    }
}

.Checkbox--dark.Checkbox--selected {
    background-color: $neutral-98;
    border-color: $neutral-70;

    .Icon {
        @include svgColor($blue-500);
    }
}

.Checkbox.Checkbox--error {
    // no design for this as of July 2020
    border-color: $red-700;
}
