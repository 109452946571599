@import '../../../styles/partials/index';

.NuggetBucket {
    height: 100%;
    position: relative;

    .Section {
        display: flex;
        align-items: center;
        overflow-x: auto;
        min-height: 50px;
        padding: 0;
    }
}

.NuggetBucket__content {
    margin: auto;
    display: flex;

    .Nugget {
        margin-left: 10px;

        &:last-child {
            margin-right: 10px;
        }
    }
}

.NuggetBucket__placeholder {
    @extend %bold14;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    color: $neutral-40;
}

.NuggetBucket__dropArea {
    flex: 1 1 100%;
    width: 100%;
    height: 100%;
    min-height: inherit;
}

.NuggetBucket--vertical {
    .Section,
    .NuggetBucket__content {
        flex-flow: column;
    }

    .Nugget {
        margin-left: 0;
        margin-top: 10px;

        &:last-child {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}
