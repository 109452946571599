@import '../../../styles/partials/index';

.Logo {
    height: 25px;
    width: 25px;

    border-radius: 20%;
}

.Logo--small {
    height: 18px;
    width: 18px;
    border: 0;
}

.Logo__inner {
    height: 100%;
    width: 100%;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: inherit;

    img {
        max-height: 100%;
        max-width: 100%;
    }

    div {
        text-align: center;
        height: 100%;
        width: 100%;
    }
}
