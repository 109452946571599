@import '../../../styles/partials/index';

.Card {
    @extend %boxShadowLight;
    background-color: $white;
    border: 1px solid $neutral-90;
    border-radius: 10px;
    padding: 10px;

    position: relative;
    height: inherit;
    width: inherit;
}
