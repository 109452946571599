@import '../../../styles/partials/index';

.FullScreenModal {
    height: 100vh;
    width: 100vw;
    z-index: 1;

    overflow: auto;
    background-color: $white;

    > header {
        margin-bottom: 15px;
        background-color: $white;
        box-shadow: 0 4px 6px rgba($neutral-20, 0.2);
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 10px 15px;
        z-index: 2;
        position: relative;
    }
}

.FullScreenModal--animationEnabled {
    animation: slideInFromRight 1s ease-in;

    &.FullScreenModal--out {
        animation: slideOutFromLeft 1s ease-in;
        pointer-events: none;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slideOutFromLeft {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

.FullScreenModalWorkflowHeader__title {
    @extend %bold20;
    text-align: center;
    margin: 5px 0;
}

.FullScreenModalWorkflowHeader__close {
    position: absolute;
    right: 5px;
    top: 5px;
}

.FullScreenModalWorkflowHeader__flex {
    display: flex;
    padding: 0 35px;

    .WorkflowStepper {
        margin: 0 20px;
        flex: 1;
    }
}
