@import '../../../styles/partials/index';

.Input {
    // font is placed at the highest level of the input component so that other components can easily modify it if needed
    @extend %regular14;
    position: relative;
    text-overflow: ellipsis;
}

.Input * {
    overflow: hidden;
}

.Input__label {
    @extend %semibold14;
    @extend %shortTransition;
    display: block;
    margin-bottom: 2px;
}

.Input__required {
    margin-left: 3px;
    color: $neutral-60;
}

.Input:focus-within .Input__label {
    transition: none;
}

.Input__error {
    @extend %regular12;
    text-align: right;
    position: absolute;
    right: 0;
    padding: 5px 0 5px 5px;
}

.Input__error > .Icon {
    position: relative;
    margin-left: 5px;
    top: 2px;
}

.Input__container {
    display: flex;
    flex-direction: column;
    // list input heights are difficult to manage, this makes it a bit easier  (TODO: improve?)
    height: inherit;
    max-height: inherit;
    text-overflow: inherit;
}

.Input__info {
    position: absolute;
    right: 0;
    top: 1px;
}

.Input__container--inlineLabel {
    flex-direction: row;
    align-items: center;
    position: relative;

    .Input__label {
        margin-right: 8px;
        margin-bottom: 0;
        text-align: right;
    }

    .Input__info {
        position: static;
        display: flex;
        align-items: center;
        margin-left: 3px;
    }

    .InputBorder:not(.InputBorder--autosize) {
        flex-grow: 1;
    }

    // used for select inputs
    .css-2b097c-container {
        flex-grow: 1;
    }
}

.Input__container--labelRight {
    .Input__label {
        text-align: right;
    }
}

.Input__container--inlineLabel.Input__container--labelRight {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .Input__label {
        margin-right: 0;
        margin-left: 8px;
        text-align: left;
    }
}

.Input--disabled {
    opacity: 0.65;
    -webkit-text-fill-color: $neutral-40; //prevents Safari from overriding disabled input text color

    &.Input--Dark {
        -webkit-text-fill-color: $white;
    }
}

.Input--hidden {
    display: none;
}

// Input label colours
.Input__label {
    color: $neutral-40;
}

.Input:focus-within .Input__label--responsive {
    color: $blue-500;
}

.Input.Input--error .Input__label {
    // three levels of specificity to override the above label color
    color: $red-700;
}

.Input.Input--dark .Input__label {
    // three levels of specificity to override the above label color
    color: $white;
}

// Input error colours
.Input__error {
    @include svgColor($red-700);
    color: $red-700;
}

.Input--dark .Input__error {
    color: $white;
}
