@import '../../../styles/partials/index';

.WorkflowStepper {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-around;
    align-items: center;
}

.WorkflowStepper__backButton {
    margin-right: 20px;
}

.WorkflowStepper__forwardButton {
    margin-left: 20px;
}

.WorkflowStepper__step {
    flex: 1 1 100%;
    position: relative;
}

.WorkflowStepper__tabLabel {
    flex: 1 1 100%;
    text-align: center;
    margin-top: 10px;
}

.WorkflowStepper__lines {
    flex: 1 1 100%;
    display: flex;
}

.WorkflowStepper__line {
    color: $neutral-90;
    border-top: solid 3px;
    flex-grow: 1;
    margin-top: 10px;
}

.WorkflowStepper__midCircle {
    height: 15px;
    width: 15px;
    background-color: $blue-300;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 7px);
    top: 30%;
}

.WorkflowStepper__tab {
    @extend %regular14;
    @include svgColor($neutral-80);
    color: $neutral-60;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: $white;
    z-index: 1;
    text-decoration: none;
}

.WorkflowStepper__tab--previous {
    @include svgColor($neutral-95);
}

.WorkflowStepper__tab--complete,
.WorkflowStepper__tab--previous {
    .WorkflowStepper__line {
        color: $blue-300;
    }
}

.WorkflowStepper__tab.WorkflowStepper__tab--active {
    @extend %bold14;

    &:not(.WorkflowStepper__tab--complete) .WorkflowStepper__line:first-child {
        color: $blue-300;
    }
}
