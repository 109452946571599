@import '../../../../styles/partials/index';

.ErrorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    text-align: center;
    height: 100%;
    width: 100%;
}

.ErrorPage__text {
    margin: 10px;
}

.ErrorPage__title {
    @extend %bold24;
    margin-bottom: 10px;
}

.ErrorPage__message {
    @extend %regular14;
    max-width: 475px;
    width: 100%;
}

.ErrorPage__errorCode {
    @extend %regular14;
    margin-top: 10px;
    margin-bottom: 20px;
}

.ErrorPage__buttons {
    display: flex;
    justify-content: center;
    .Button {
        margin: 0 10px;
    }
}
