@import '../../../styles/partials/index';

.TabHeader {
    display: flex;
    margin-bottom: 10px;
}

.Tabs__main {
    overflow: auto;

    > .Loader {
        margin: auto;
    }
}

.Tabs__tab {
    @extend %semibold14;
    color: $neutral-60;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    border-bottom: 2px solid $neutral-90;

    &:hover,
    &.Tabs__tab--active {
        @extend %bold14;
        color: $blue-400;
        outline: none;
        border-bottom: 2px solid $blue-300;
    }
}
