@import '../../../../../styles/partials/index';

.YearCalendar {
    background-color: $neutral-60;
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 25px;
    border-radius: 5px;
}

.YearCalendar__month {
    height: 20px;
    width: 40px;
    margin: 2.5px auto;
    border-radius: 4px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $neutral-50;
    }
}

.YearCalendar__month--invalid {
    color: $neutral-70;
    cursor: default;

    &:hover {
        background-color: inherit;
    }
}

.YearCalendar__month--current {
    background-color: $blue-500;

    &:hover {
        background-color: $blue-500;
    }
}
