@import '../../../../styles/partials/index';

.InputBase {
    padding: 4px 8px 4px 12px;
    background: transparent;
    color: inherit;

    border: none;
    outline: none;

    width: 100%;
    min-height: 32px;

    font: inherit;
    text-overflow: inherit;

    &::placeholder {
        // placeholder colors live in .InputBorder
        // -- this is here in case any input do not use .InputBorder
        color: $neutral-60;
    }
}

.AutosizeInputContainer {
    text-overflow: inherit;
    overflow-x: auto;
}

input.InputBase {
    // additional specificity to overwrite .Input *
    // the autosize input library applies box-sizing: content-box to the element itself
    // adding this keeps things consistent.
    box-sizing: content-box;
}

.InputBase--autosize {
    padding: 4px 12px;
    max-width: inherit;
}

.InputBase--small {
    padding: 2px 8px 2px 12px;
    width: 100%;
    min-height: 21px;

    &.InputBase--autosize {
        padding: 2px 12px;
    }
}

.InputBase--disabled {
    opacity: 1;
}
