@import '../../../../styles/partials/index';

.Radio {
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 15px;
    }
}
