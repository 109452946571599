%animationFadeIn {
    animation: fadein 0.25s;
}

%shortTransition {
    transition: 0.15s ease-in;
}

%shorterTransition {
    transition: 0.1s ease;
}
