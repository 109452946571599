/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
/* TODO: these styles could be rewritten */
@import '../../../../styles/partials/index';

.Slider--inline {
    display: flex;
    align-items: center;

    .TextInput {
        flex: 0 0 auto;
        margin-right: 5px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

.Slider {
    .rc-slider {
        min-width: 90px;
    }

    .rc-slider-rail {
        background-color: $neutral-80;
    }

    .rc-slider-track {
        background-color: $blue-500;
    }

    .rc-slider-handle {
        border-color: $blue-500;

        &:focus {
            box-shadow: 0 0 0 5px $blue-400;
            outline: none;
        }

        &.rc-slider-handle-click-focused {
            box-shadow: unset;

            &:active {
                box-shadow: 0 0 5px $blue-500;
            }
        }

        &:active {
            box-shadow: 0 0 5px $blue-500;
        }
    }

    .rc-slider-dot {
        border-color: $neutral-80;

        &.rc-slider-dot-active {
            border-color: $blue-500;
        }
    }

    .rc-slider-mark-text {
        color: $neutral-70;
    }

    .Slider__label {
        @extend %semibold14;
        position: absolute;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rc-slider-vertical {
        min-height: 140px;
    }

    .rc-slider-disabled {
        background-color: $neutral-95;

        .rc-slider-rail {
            background-color: $neutral-95;
        }

        .rc-slider-track {
            background-color: $neutral-80;
        }

        .rc-slider-handle {
            border-color: $neutral-80;
            cursor: auto;
        }

        .Slider__label {
            color: $neutral-80;
        }
    }
}

.Input--dark {
    .Slider {
        .Slider__label {
            color: $white;
        }

        .rc-slider-mark-text {
            color: $neutral-95;
        }

        .rc-slider-dot {
            background-color: $neutral-60;
        }

        .rc-slider-disabled {
            background-color: transparent;

            .Slider__label {
                color: $neutral-95;
            }
        }
    }
}
