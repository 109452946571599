@import '../../../../styles/partials/index';

.MultiCheckbox {
    display: flex;
    flex-direction: column;

    > * + * {
        margin-top: 15px;
    }
}

.MultiCheckbox__row {
    display: flex;
    -webkit-tap-highlight-color: transparent;

    .Checkbox {
        margin-right: 8px;
    }

    label {
        flex: 1;
    }
}

.MultiCheckbox--dark label {
    color: $white;
}

.MultiCheckbox--disabled .Checkbox {
    cursor: unset;
    border-width: 1px;
}
