@import '../../../styles/partials/index';

.Section {
    position: relative;
    height: 100%;
    padding: 10px;

    border: 1px dashed transparent;
    border-radius: $border-radius;
}

.Section--selected {
    border-color: $blue-500;
}

.Section--error {
    border-color: $red-700;
}

.Section--unselected {
    border-color: $neutral-80;
}
