/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
/* TODO: these styles need to be rewritten */
@import '../../../styles/partials/index';

.Table {
    max-height: 100%;
    border: 1px solid $neutral-90;
    border-radius: 10px;
    position: relative;
    overflow: auto;
    background-color: $white;

    border-collapse: collapse;
    border-spacing: 0;

    table {
        text-align: left;
        width: 100%;
        border-spacing: 0;

        * {
            border-spacing: 0;
        }

        thead {
            background-color: $neutral-95;
            max-height: 30px;
        }

        th,
        td {
            padding: 0 10px;
            height: 38px;
        }

        td {
            border: 1px solid transparent;

            &:first-child {
                border-top-left-radius: 9px;
                border-bottom-left-radius: 9px;
            }

            &:last-child {
                border-bottom-right-radius: 9px;
                border-top-right-radius: 9px;
            }
        }

        th {
            background-color: $neutral-95;
            color: $neutral-50;
            border-top-width: 0;
            border-bottom: 1px solid $neutral-90;
            height: 28px;
            position: sticky;
            top: 0;

            &:first-child {
                border-top-left-radius: 9px;
            }

            &:last-child {
                border-top-right-radius: 9px;
            }
        }

        tr {
            margin: 0 -1px;

            &:hover {
                td {
                    background-color: $neutral-95;
                    border-top-color: $neutral-90;
                    border-bottom-color: $neutral-90;

                    &:first-child {
                        border-left-color: $neutral-90;
                    }

                    &:last-child {
                        border-right-color: $neutral-90;
                    }
                }
            }

            &.Table__row--active {
                color: $white;
                border: none;

                td {
                    background-color: $blue-500;
                }

                td:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                td:last-child {
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            &.Table__row--disabled {
                background-color: $white;
                color: $neutral-95;

                &:hover {
                    color: $neutral-90;
                }
            }
        }

        .Loader {
            height: 17px;
            padding: 10px;
            display: block;

            svg {
                border-radius: 4px;
            }
        }
    }

    .Table--pageLoading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $neutral-90;
        display: flex;
        align-items: center;
        justify-content: center;

        .Loader {
            transform: scale(1.3);
        }
    }

    &.Table--pagination {
        overflow: unset;

        .Table__table {
            overflow: auto;
        }

        .Table--pageLoading {
            bottom: 39px;
        }
    }

    footer {
        display: none;
        position: relative;
        background-color: $neutral-95;
        border-top: 1px solid $neutral-90;
        height: 40px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .Button {
            @extend %regular12;
            position: absolute;
            top: 5px;

            &:first-child {
                left: 8px;
            }

            &:last-child {
                right: 8px;
            }
        }

        .Table__paginationInput {
            text-align: center;
            margin-top: 4px;

            span {
                margin-left: 5px;
                margin-right: 5px;
            }

            .Form {
                display: inline;

                .Input {
                    display: inline-block;

                    .TextBox {
                        min-height: 30px;

                        .TextBox__input {
                            text-align: center;
                            padding: 0;
                            height: 28px;
                            width: 35px;
                        }
                    }
                }
            }
        }
    }

    &.Table--pagination {
        tr {
            &:last-child {
                td {
                    border-bottom-width: 0;

                    &:first-child {
                        border-bottom-left-radius: 0;
                    }

                    &:last-child {
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }

        footer {
            display: block;
        }
    }

    &.Table--noData {
        tr:hover {
            background-color: inherit;
        }

        td {
            color: $neutral-70;
            text-align: center;
        }
    }
}
