@import '../../../../styles/partials/index';

.ModalCard {
    max-height: 95vh;
    max-width: 95vw;
}

.ModalCard > .Card {
    padding: 20px;
    max-height: 95vh;
    max-width: 95vw;
    overflow: auto;
}

.ModalCard__header {
    display: flex;
    outline: none;
}

.ModalCard__titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.ModalCard__title {
    @extend %bold24;
    text-align: left;
    margin-right: 25px;
}

.ModalCard__subtitle {
    margin-top: 4px;
}

.ModalCard__footer {
    display: flex;
    justify-content: flex-end;

    .Button {
        margin-left: 10px;
        margin-top: 15px;
    }
}

.ModalCard__close {
    position: absolute;
    top: 20px;
    right: 20px;
}
