@import '../../../styles/partials/index';

.CollapsibleSection {
    // adding these three styles to facilitate scrolling of CollapsibleSection__main
    //  -- other components can make the __main scroll by giving the CollapsibleSection itself a height (or flex) value
    display: flex;
    flex-direction: column;
    overflow: hidden;

    // make room for the scroll bar?
    margin-right: -12px;
    padding-right: 12px;

    min-height: 21px; // a magic number (= height of collapsibleSection__title) (make sure this doesn't shrink smaller than this inside a flex container)

    .IconButton {
        margin-bottom: 2px;
    }
}

.CollapsibleSection__title {
    @extend %bold14;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $neutral-20;

    user-select: none; // double clicking on the title would hilight all the content in the children
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.CollapsibleSection__title--underline {
    border-bottom: 1px solid $neutral-60;
}

.CollapsibleSection__title--open {
    margin-bottom: 10px;
}

.CollapsibleSection__main {
    overflow: auto;
    margin-right: -12px;
    padding-right: 12px;
}
