@import '../../../../styles/partials/index';

.TitleElement,
.QuestionElement {
    p {
        @extend %regular14;
        color: $neutral-60;
    }
}

.TitleElement {
    animation: fade_question_in 0.5s ease 0s;
    animation-fill-mode: both;

    h1 {
        @extend %bold24;
        margin: 0;
    }

    p {
        margin-top: 10px;
    }
}

.QuestionElement {
    animation: fade_question_in 0.5s ease 0s;
    animation-fill-mode: both;

    h3 {
        @extend %bold18;
        margin: 0;
    }

    p {
        margin-top: 3px;
    }

    .Input {
        margin-top: 10px;
    }
}

.QuestionElement--required {
    h3::after {
        content: ' *';
        color: $neutral-70;
    }
}

.QuestionElement--hidden {
    opacity: 0;
    transform: translate(0, 20px);
}

@keyframes fade_question_in {
    from {
        transform: translate(0, 20px);
        opacity: 0;
    }
}
