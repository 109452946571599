@import '../../../styles/partials/index';

.DragAndDropList {
    .DragAndDropListItem { display: none; }
}

.DragAndDropList--expanded {
    .DragAndDropListItem { display: block; }
    .Section { min-height: 40px; }
}

.DragAndDropList--isOver {
    .DragAndDropList__title {
        color: $blue-600;
    }

    .Section {
        margin: 5px;
    }
}

.DragAndDropList__emptyPlaceholderText {
    padding: 0 20px;
    color: $neutral-60;
    min-height: 40px;
}


.DragAndDropList__display {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 2px;
}

.DragAndDropList__title {
    @extend %semibold14;
    margin-left: 5px;
}


.DragAndDropList__buttons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.DragAndDropList__type {
    line-height: 0; // vertically  center any Icons
}

.DragAndDropList--collapsible {
    margin-left: 25px;

    .DragAndDropList__display {
        cursor: pointer;
        outline: none;
    }
}

.DragAndDropList__collapsibleIcon {
    margin-left: -25px;
    margin-right: 5px;
}
