@import '../../../../styles/partials/index';

.RadioOption {
    label {
        @extend %regular14;
        color: $neutral-20;
        display: flex;
        flex-direction: row;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    label:hover .RadioOption__radioButton {
        box-shadow: inset 0 0 0 2px $neutral-80;
    }

    input:checked ~ .RadioOption__radioButton {
        box-shadow: inset 0 0 0 1px $neutral-80, inset 0 0 0 3px $white, inset 0 0 0 4px $blue-600;
        background-color: $blue-400;
    }

    input[type='radio'] {
        margin: 1px;
        height: 17px;
        width: 17px;
    }
}

.RadioOption__radioButton {
    @extend %shortTransition;
    height: 18px;
    width: 18px;
    background-color: $white;
    box-shadow: inset 0 0 0 1px $neutral-80;
    border-radius: 50%;
    flex-shrink: 0;
    margin: 0 1px; // prevents overflow of border on page resize
}

.RadioOption__label {
    margin-left: 7px;
}

.RadioOption--dark {
    .RadioOption__radioButton {
        box-shadow: inset 0 0 0 1px $neutral-80;
        background-color: $neutral-50;
    }

    input:checked ~ .RadioOption__radioButton {
        box-shadow: inset 0 0 0 1px $neutral-50, inset 0 0 0 3px $white, inset 0 0 0 4px $blue-600;
    }

    .RadioOption__label {
        color: $white;
    }
}

.RadioOption--error {
    .RadioOption__radioButton {
        box-shadow: inset 0 0 0 1px $red-700;
    }
}

.RadioOption--disabled {
    opacity: 0.65;

    input {
        cursor: unset;
    }
    label:hover .RadioOption__radioButton {
        box-shadow: inset 0 0 0 1px $neutral-80;
    }
    input:checked ~ .RadioOption__radioButton {
        box-shadow: inset 0 0 0 1px $neutral-80, inset 0 0 0 3px $white, inset 0 0 0 4px $blue-600;
        background-color: $blue-400;
    }
}
