@import '../../../styles/partials/index';

.ConfirmDeleteMenu {
    @extend %bold14;
    margin: 10px;
    white-space: nowrap;
}

.ConfirmDeleteMenu__buttons {
    display: flex;
    justify-content: flex-end;

    .Button {
        margin: 10px 5px 0;
    }
}
